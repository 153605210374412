import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { AlertService } from './alert.service';
import { NbDialogService } from '@nebular/theme';

@Injectable({
  providedIn: "root",
})
export class NavigationService {

  constructor(
    public router: Router,
    public alertService: AlertService,
    private dialogService: NbDialogService,
  ) {
  }

  /**
   * Se encarga de navegar a la URL especificada
   * @param {string} path -> "/dashboard/inventario/:id"
   * @return void
   */
  public routerNavigateTo({ path }: { path: string }): void {
    this.router.navigate([path]).then((fulfilled) => {
      if (!fulfilled) {
        this.alertService.showToastr({
          title: "Dirección URL no válida.",
          status: "basic",
        });
      }
    });
  }

  /**
   * Abre un dialogo estilo nebular
   * @param  {any} dialog referencia al template en html
   * que contiene el dialogo. Debería der tipye ElementRef o TypeReference
   * @returns void
   */
  public showDialog({ dialog }: { dialog: any }) {
    return this.dialogService.open(dialog, { context: "" });
  }

}